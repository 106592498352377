/**
 * 获取二级导航数据
 * @param {*} name as String 一级导航名称
 */
export function getSubNav(name) {
    const navData = JSON.parse(JSON.stringify(this.$router.options.routes));
    let navItem = navData.filter(item => item.meta.name === name);
    if (navItem.length > 0) {
        navItem = navItem.map(item => {
            if (item.children && item.children.length > 0) {
                if (item.meta.rank.length === 1) {
                    item.children = item.children.filter(val => {
                        if (!val.redirect) {
                            return val;
                        }
                    });
                }
                else {
                    const {query} = this.$route;
                    if (query && query.isStore) {
                        item.children = item.children.filter(val => {
                            if (!val.redirect && (!val.meta.rank || val.meta.rank.indexOf(2) >= 0)) {
                                return val;
                            }
                        });
                    }
                    else {
                        item.children = item.children.filter(val => {
                            if (!val.redirect && (!val.meta.rank || val.meta.rank.indexOf(+this.$store.state.account.rank) >= 0)) {
                                return val;
                            }
                        });
                    }
                }
            }
            return item;
        });
    }
    return navItem[0];
}

/**
 * 获取面包屑导航数据
 * @param {*} name 一级导航名称
 * @param {*} name2 二级导航名称
 */
export function getBread(name, name2) {
    const routes = getSubNav.call(this, name);
    if (routes.children.length > 0) {
        const index = routes.children.findIndex(val => val.name === name2);
        return {
            name1: routes.meta.title,
            name2: routes.children[index].meta.name
        };
    }
    else {
        return {};
    }
}

/**
 * 下载订单到本地
 * @param {*} list 订单列表数据
 */
import * as XLSX from 'xlsx/xlsx.mjs';
export function downxls(list = []) {

    // 表单展示的数据
    const xlsList = list.map(item => {
        return {
            id: item.id,
            out_trade_no: item.out_trade_no,
            openid: item.openid,
            zhangOpenid: item.zhangOpenid,
            shouldPay: item.shouldPay,
            total: item.total,
            storeId: item.storeId,
            storeOpenid: item.storeOpenid,
            storeName: item.storeName,
            couponId: item.couponId,
            isStoreCoupon: item.isStoreCoupon,
            manjian: item.manjian,
            youhui: item.youhui,
            yue: item.yue,
            discountAmount: item.discountAmount,
            cashStatus: item.cashStatus,
            qianggou: item.qianggou,
            goodsTitle: item.goodsTitle,
            goodsPrice: item.goodsPrice,
            goodsDate: item.goodsDate,
            ptDiscount: item.ptDiscount,
            ptZhangYongjin: item.ptZhangYongjin,
            dateTime: item.dateTime
        }
    });
    
    const worksheet = XLSX.utils.json_to_sheet(xlsList);

    // 定义表头数据
    const headers = ['ID', '订单号', '用户ID', '团长ID', '应付款', '实付款', '店铺ID', '店长ID', '店铺名称', '优惠券ID', '优惠券所属店铺ID(为空属于平台)', '满减金额', '优惠金额', '余额支付金额', '店铺折扣金额', '提现状态', '是否是抢购商品(为空不是)', '抢购商品标题', '抢购商品现价', '抢购商品活动日期', '平台抽成(%)', '团长抽成(%)', '订单生成时间'];
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 0 });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `orderList.xlsx`);
}

export function downStore(list = []) {
    // 表单展示的数据
    const xlsList = list.map(item => {
        return {
            id: item.id,
            name: item.name,
            tuijianyuanNumber: item.tuijianyuanNumber,
            moneySum: item.moneySum
        }
    });

    const worksheet = XLSX.utils.json_to_sheet(xlsList);

    // 定义表头数据
    const headers = ['ID', '店铺名称', '推荐员编号', '推荐员抽成'];
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 0 });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `storeList.xlsx`);
}

/**
 * 绘制小程序+店铺名称
 * @param {*} src 小程序码地址
 * @param {*} text 店铺名称
 */
export function drawImageAndText(src, text) {
    let canvas = document.createElement('canvas');
    // 设置canvas的属性
    canvas.setAttribute('id', 'myCanvas');
    canvas.setAttribute('width', '430');
    canvas.setAttribute('height', '600');

    let ctx = canvas.getContext('2d');

    // 创建一个新的图片对象
    let img = new Image();

    // 设置图片的源地址
    img.src = src;

    // 图片加载完成后执行的函数
    img.onload = () => {
        console.log('canvas', canvas);

        // 设置背景色为白色
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // 将图片绘制到 canvas 上
        ctx.drawImage(img, 0, 0, canvas.width, img.height * (canvas.width / img.width));

        // 设置文本样式
        ctx.fillStyle = 'black';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = '20px Arial';

        // 绘制文本
        let line = '';
        let y = img.height * (canvas.width / img.width) + 10;

        for(let n = 0; n < text.length; n++) {
            const testLine = line + text[n];
            const metrics = ctx.measureText(testLine);
            const testWidth = metrics.width;
            if (testWidth > canvas.width && n > 0) {
                ctx.fillText(line, canvas.width / 2, y);
                line = text[n];
                y += 60; // 字体的高度
            }
            else {
                line = testLine;
            }
        }

        ctx.fillText(line, canvas.width / 2, y);

        setTimeout(() => {
            let dataUrl = canvas.toDataURL('image/png');
            let a = document.createElement('a');
            a.href = dataUrl;
            a.download = `ewm_${Date.now()}.png`;
            a.click();
        });
    };
}
